<template>
  <div class="index-container">
    <div class="head">
      <yicangHead class="yicang-head"></yicangHead>
      <headNav currentPage="新闻资讯" class="head-nav"></headNav>
    </div>

    <div class="body">
      <newsDetailPage></newsDetailPage>
    </div>

    <div class="footer">
      <footerBox></footerBox>
    </div>
  </div>
</template>

<script>
// head
import yicangHead from "../components/header/head";
import headNav from "../components/header/headNav";
// main
import newsDetailPage from "../components/container/news/newsDetail";
// footer
import footerBox from "../components/footers/index";

export default {
  name: "NewsDetail",
  components: {
    yicangHead,
    headNav,
    newsDetailPage,
    footerBox
  },
  methods: {
  }
};
</script>
