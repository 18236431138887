<template>
  <div class="news-detail-container">
    <div class="head-nav fs-16 fc-3">
      <span class="back" @click="goBack">返回上一页</span>
      <span>新闻资讯</span> >
      <span>资讯详情</span>
    </div>
    <div class="detail-content">
      <div class="news-title fs-24 fc-1">{{detail.title}}</div>
      <div class="news-date fs-14 fc-6">日期：{{detail.created_at}}</div>
      <div class="news-content fs-16 fc-3" v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { API } from "@/const/const";
import { reWriteContent } from "@/utils/utils"

export default {
  name: "newsDetail",
  data() {
    return {
      detail: {}
    };
  },
  mounted() {
    const { id = 0 } = this.$route.query;
    this.getDetail(id);
  },
  methods: {
    getDetail(id) {
      request.get(API.GET_NEWS_LIST + `/${id}`).then(res => {
        this.detail = res;
        this.detail.content = reWriteContent(this.detail.content)
      });
    },
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="less" scoped>
.news-detail-container {
  padding: 0.56rem 2.8rem;
  min-height: 4rem;
  .news-title {
    text-align: center;
    padding: 0.36rem 0;
    font-weight: 600;
  }
  .back {
    cursor: pointer;
    padding-right: .1rem;
  }
  .news-date {
    padding-bottom: 0.3rem;
    text-align: center;
  }
  .news-content {
    border-top: 0.01rem solid #eee;
    padding-top: 0.32rem;
    p {
      text-align: left;
    }
    img {
      width: 100%;
    }
  }
}
</style>